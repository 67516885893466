import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import BookingForm from './components/BookingForm';
import AdminView from './components/AdminView';
import Success from './components/Success';
import Login from './components/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const App = () => {
  const [bookingInfo, setBookingInfo] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleBookingSuccess = (firstName, lastName, timeslot) => {
    setBookingInfo({ firstName, lastName, timeslot });
  };

  const PrivateRoute = ({ element, ...rest }) => {
    return isLoggedIn ? element : <Navigate to="/login" />;
  };

  return (
    <Router>
      <div className="App">
        <Navbar />
        <img
          src={`https://images.unsplash.com/photo-1571772996211-2f02c9727629?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`}
          alt="Banner"
          className="banner-image"
        />
        <div className="container mt-4">
          <Routes>
            <Route path="/" element={<BookingForm onBookingSuccess={handleBookingSuccess} />} />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/bookings" element={<PrivateRoute element={<AdminView />} />} />
            <Route path="/success" element={<Success {...bookingInfo} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
