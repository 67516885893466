import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './BookingForm.css'; // Import the CSS file

const BookingForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL || 'https://server-ruddy-three.vercel.app';

  useEffect(() => {
    if (date && apiUrl) {
      const fetchTimeSlots = async () => {
        try {
          const response = await axios.get(`${apiUrl}/api/timeslots`, {
            params: { date }
          });
          console.log('API Response:', response.data); // Log the response
          if (Array.isArray(response.data)) {
            setTimeSlots(response.data);
          } else {
            console.error('Expected an array but got:', response.data);
            setTimeSlots([]);
          }
        } catch (error) {
          console.error('Error fetching time slots:', error);
          setError('Failed to load time slots. Please try again later.');
          setTimeSlots([]);
        }
      };

      fetchTimeSlots();
    }
  }, [date, apiUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY, { action: 'submit_form' });

      // Proceed with user creation and booking submission
      const userResponse = await axios.post(`${apiUrl}/api/users`, { firstName, lastName, email });
      
      await axios.post(`${apiUrl}/api/bookings`, {
        user: userResponse.data._id,
        slot: selectedSlot,
        token // Ensure token is sent
      });

      const slot = timeSlots.find(slot => slot._id === selectedSlot);

      navigate('/success', {
        state: {
          firstName,
          lastName,
          email,
          timeslot: slot ? slot.slotTime : null
        }
      });
    } catch (error) {
      console.error('Error occurred:', error);
      setError('Failed to complete the booking. Please try again later.');
    }
  };

  return (
    <div className="container">
      <img
        src={`https://patientmpower.com/wp-content/uploads/2021/04/galways.png`}
        alt="Banner"
        className="banner-image-logo"
      />
      <img
        src={`https://www.phoenixs.co.uk/wp-content/uploads/2023/01/Imprivata-logo.png`}
        alt="Banner"
        className="banner-image-logo banner-image-logo-right"
      />
      <div className="intro-text">
        <p>
          Great news! Imprivata Single Sign-On is coming to Galway University Hospitals! We will be running an Enrolment fair in order to get staff set up on the system.
          Please book your preferred time slot using the form below.
        </p>
      </div>

      <div className="booking-form-container">
        <form className="booking-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="HSE Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <select value={date} onChange={(e) => setDate(e.target.value)} required>
            <option value="">Select a date</option>
            <option value="2024-07-10">July 10, 2024 - UHG</option>
            <option value="2024-07-11">July 11, 2024 - UHG</option>
            <option value="2024-07-12">July 12, 2024 - UHG</option>
            <option value="2024-07-15">July 15, 2024 - Merlin Park</option>
            <option value="2024-07-16">July 16, 2024 - Merlin Park</option>
            <option value="2024-07-18">July 18, 2024 - Merlin Park</option>
          </select>
          <select value={selectedSlot} onChange={(e) => setSelectedSlot(e.target.value)} required>
            <option value="">Select a time slot</option>
            {Array.isArray(timeSlots) && timeSlots.map(slot => (
              <option key={slot._id} value={slot._id}>
                {new Date(slot.slotTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </option>
            ))}
          </select>
          {/* Displaying error message */}
          {error && <p className="error-message">{error}</p>}
          <button type="submit">Book</button>
        </form>
      </div>
    </div>
  );
};

export default BookingForm;
