import React from 'react';
import { useLocation } from 'react-router-dom';
import './Success.css'; // Import the CSS file

const Success = () => {
  const location = useLocation();
  const { firstName, lastName, email, timeslot } = location.state || {};

  return (
    <div className="success-container">
      <div className="success-message">
        <h2>Booking Successful!</h2>
        <p>Thank you for booking a time slot. We look forward to Enroling you on the day.</p>
        <p><span className="label">Name:</span> {firstName} {lastName}</p>
        <p><span className="label">Email:</span> {email}</p>
        <p><span className="label">Timeslot:</span> {timeslot ? new Date(timeslot).toLocaleString() : 'Invalid Date'}</p>
      </div>
    </div>
  );
};

export default Success;
