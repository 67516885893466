import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AdminView.css';

const AdminView = () => {
  const [bookings, setBookings] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL || 'https://server-ruddy-three.vercel.app';

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/bookings`);
        setBookings(response.data);
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    fetchBookings();
  }, [apiUrl]);

  const groupedBookings = bookings.reduce((acc, booking) => {
    const dateKey = new Date(booking.slot.slotTime).toDateString();
    if (!acc[dateKey]) acc[dateKey] = [];
    acc[dateKey].push(booking);
    return acc;
  }, {});

  const sortedDates = Object.keys(groupedBookings).sort((a, b) => new Date(a) - new Date(b));

  return (
    <div className="container mb-5">
      <h2>Bookings</h2>
      {sortedDates.map(date => (
        <div key={date} className="dateGroup">
          <h3 className="dateHeader">{date}</h3>
          <ul className="list">
            {groupedBookings[date]
              .sort((a, b) => new Date(a.slot.slotTime) - new Date(b.slot.slotTime))
              .map(booking => (
                <li key={booking._id} className="listItem">
                  <div className="bookingInfo">
                    <span>{booking.user.firstName} {booking.user.lastName} - {booking.user.email}</span>
                    <span>{new Date(booking.slot.slotTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default AdminView;
